@namespace svg "http://www.w3.org/2000/svg";

:not(svg|*) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

:before, :after {
  font: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

input[type="number"] {
  -webkit-appearance: textfield;
  appearance: textfield;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  all: unset;
}

::-webkit-color-swatch-wrapper {
  all: unset;
}

::-webkit-color-swatch {
  all: unset;
}

::-moz-color-swatch {
  all: unset;
}

::-webkit-file-upload-button {
  all: unset;
}

::-webkit-search-cancel-button, ::-webkit-search-results-button, ::-webkit-search-results-decoration {
  all: unset;
}

::-webkit-slider-thumb {
  all: unset;
}

::-webkit-slider-runnable-track {
  all: unset;
}

::-moz-range-thumb {
  all: unset;
}

::-moz-range-track {
  all: unset;
}

::-moz-range-progress {
  all: unset;
}

::-webkit-datetime-edit-fields-wrapper {
  all: unset;
}

::-webkit-calendar-picker-indicator {
  all: unset;
}

[list]::-webkit-calendar-picker-indicator {
  list-style: none !important;
}

::-webkit-progress-bar {
  all: unset;
}

::-webkit-progress-value {
  all: unset;
}

::-moz-progress-bar {
  all: unset;
}

pre, textarea {
  white-space: pre-wrap;
  word-wrap: break-word;
}

input, select, textarea {
  vertical-align: bottom;
}

textarea {
  resize: auto;
}

html:focus-within {
  scroll-behavior: smooth;
}

html {
  -webkit-tap-highlight-color: transparent;
  cursor: default;
  overflow-wrap: break-word;
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font: 16px / 1.5 system-ui, sans-serif;
}

pre {
  overflow: auto;
}

abbr[title] {
  cursor: help;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

small {
  font-size: .75em;
}

b, strong {
  font-weight: 700;
}

em, i {
  font-style: italic;
}

a {
  cursor: pointer;
}

svg:not([fill]) {
  fill: currentColor;
}

code, kbd, samp, pre {
  font-family: monospace;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

li {
  list-style: none;
}

iframe, img, input, select, textarea {
  height: auto;
  max-width: 100%;
}

table {
  table-layout: fixed;
  text-indent: 0;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-ui-invalid {
  box-shadow: none;
}

:-moz-focusring {
  outline: 1px dotted;
}

:focus {
  outline: 1px dotted;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-disabled="true"], [disabled] {
  cursor: not-allowed;
}

html {
  --color-primary: #496c37;
  --color-primary-shade: #37512a;
  --color-primary-pale: #5b8745;
  --color-primary-highlight: #6da253;
  --color-text-pale: #93a989;
  --color-text: #fff;
  background-color: var(--color-primary);
  color: var(--color-text);
  scrollbar-gutter: stable;
}

@media screen and (width > 1920px) {
  html {
    font-size: 24px;
  }
}

::placeholder {
  color: var(--color-text-pale);
}

path:not([fill]) {
  fill: currentColor;
}

.Ma4OlW_layout {
  height: 100vmin;
  max-width: 64rem;
  flex-direction: column;
  margin: 0 auto;
  display: flex;
}

.Ma4OlW_topbar {
  height: 5rem;
}

.Ma4OlW_topbar h1 {
  font-size: 1.5rem;
  font-weight: 900;
}

.Ma4OlW_topbar h1 svg {
  height: 1.5rem;
}

.Ma4OlW_main {
  flex: 1 0 auto;
  justify-content: center;
  display: flex;
}

.Ma4OlW_footer {
  height: 5rem;
}

.Ma4OlW_footer a {
  font-weight: bolder;
}

.qvtmNG_flex {
  display: flex;
}

.RsMs9W_button {
  cursor: pointer;
  height: 2.25em;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  padding-block-end: .125rem;
  padding-inline: 1.5rem;
  font-size: .875rem;
  font-weight: bolder;
  display: inline-flex;
}

.RsMs9W_button[disabled] {
  opacity: .5;
}

.RsMs9W_button.RsMs9W_default {
  background-color: var(--color-text);
  color: var(--color-primary-shade);
}

.RsMs9W_button.RsMs9W_hollow {
  box-shadow: inset 0 0 0 .125rem;
}

.iFw43G_card {
  --shadow: .125rem .125rem;
  background-color: var(--color-primary-pale);
  box-shadow: 0 var(--shadow) #00000040;
  height: 5.25rem;
  width: 3.5rem;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  transition: translate .125s ease-in-out, box-shadow .125s ease-in-out;
  display: flex;
}

.iFw43G_card:not(:disabled) {
  cursor: pointer;
}

.iFw43G_card:not(:disabled, .iFw43G_selected):is(:hover, :focus) {
  --shadow: .5rem .5rem;
  background-color: var(--color-primary-highlight);
  translate: 0 -.5rem;
}

.iFw43G_card.iFw43G_selected {
  --shadow: .5rem .5rem;
  background-color: var(--color-text);
  color: var(--color-primary-shade);
  translate: 0 -.5rem;
}

._9-NHQq_face {
  height: 1.5rem;
  font: 1.5rem / 1 Space Mono, monospace;
}

.vtsT4W_icon {
  height: 1.5rem;
  vertical-align: middle;
  width: 1.5rem;
  display: inline-block;
}

.eXhapG_score {
  height: 5.25rem;
}

.eXhapG_average output {
  background-color: var(--color-text);
  color: var(--color-primary-shade);
  height: 4rem;
  min-width: 4rem;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  padding-inline: 1.5rem;
  font: 1.5rem / 1 Space Mono, monospace;
  display: flex;
  box-shadow: 0 .125rem .125rem #00000040;
}

.eXhapG_average svg {
  filter: drop-shadow(0 .125rem .125rem #00000080);
}

._2cX8Ya_player {
  width: 6rem;
}

._2cX8Ya_player figcaption {
  font-weight: bolder;
}

._2cX8Ya_player._2cX8Ya_faded {
  opacity: .5;
}

/*# sourceMappingURL=index.716e784f.css.map */
